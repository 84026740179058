import React from "react";
import { Box, CloseIcon, Heading, HStack, Pressable, Text, VStack, Link, Image } from "native-base";

export default function Side({ isSlideOpen, setSlideOpen }) {
    return (
        <Box zIndex={1000} w="100%" position="fixed" top="0" bottom="0" bg="coolGray.500" display={isSlideOpen ? "flex" : "none"}>
            <HStack justifyContent="space-between" my="6" px="7" alignItems="center">
                <Pressable onPress={() => setSlideOpen(false)}>
                    <CloseIcon size="xs" color="black" />
                </Pressable>

                <Heading fontWeight="black" color="black" size="xl" italic>
                    <Link
                        href="#"
                        onClick={() => {
                            setSlideOpen(false);
                        }}
                        _text={{ fontWeight: "medium", color: "white", fontSize: "lg" }}
                        isUnderlined={false}
                    >
                        <HStack alignItems="center">
                            <Image width={16} height={16} src={"/images/logo.png"} alt="마루미디어" mr="2" />

                            <Text color={"#f3f4f6"} fontWeight="medium" fontSize="lg">
                                MaruMedia
                            </Text>
                        </HStack>
                    </Link>
                </Heading>
            </HStack>
            <VStack py="2" px="7" space="8" mt="8">
                <Link
                    href="#OurService"
                    onClick={() => {
                        setSlideOpen(false);
                    }}
                    _text={{ fontWeight: "medium", color: "white", fontSize: "lg" }}
                    isUnderlined={false}
                >
                    OurService
                </Link>

                <Link
                    href="#Portfolio"
                    onClick={() => {
                        setSlideOpen(false);
                    }}
                    _text={{ fontWeight: "medium", color: "white", fontSize: "lg" }}
                    isUnderlined={false}
                >
                    Portfolio
                </Link>
                <Link
                    href="#Production"
                    onClick={() => {
                        setSlideOpen(false);
                    }}
                    _text={{ fontWeight: "medium", color: "white", fontSize: "lg" }}
                    isUnderlined={false}
                >
                    Production
                </Link>

                <Link
                    href="#Contact"
                    onClick={() => {
                        setSlideOpen(false);
                    }}
                    _text={{ fontWeight: "medium", color: "white", fontSize: "lg" }}
                    isUnderlined={false}
                >
                    Contact
                </Link>
            </VStack>
        </Box>
    );
}
