import React, { useState } from "react";
import { Box, VStack } from "native-base";

import Head from "./Head";
import Body from "./Body";
import Footer from "../Footer";
import Side from "./Side";

export default function Home() {
    const [isSlideOpen, setSlideOpen] = useState(false);

    return (
        <>
            <section id="top"></section>

            <Box w="100%" overflow="hidden">
                <VStack alignItems="center" w="100%" bg="trueGray.400" pb="10">
                    <Head setSlideOpen={setSlideOpen} />

                    <Body />

                    <section id="Contact"></section>
                    <Footer />
                </VStack>
            </Box>
            <Side isSlideOpen={isSlideOpen} setSlideOpen={setSlideOpen} />
        </>
    );
}
