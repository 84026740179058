import React from "react";
import { Box, Center, Divider, Heading, HStack, Text, useBreakpointValue, VStack, Hidden, Image } from "native-base";

import Portfolio from "../Portfolio";
import Ourwork from "../Ourwork";
import Production from "../Production";

import { EntypoLightBulb } from "react-entypo";

export default function Body() {
    const moduleWidth = useBreakpointValue({
        base: 400,
        md: 500,
    });

    const introHeading = { textAlign: "center", my: 2, color: "coolGray.700", fontSize: { base: "sm", md: "lg" }, lineHeight: "md" };
    const introHeading2 = { textAlign: "center", my: 2, color: "coolGray.700", fontSize: { base: "sm", md: "xl" }, lineHeight: "lg" };

    return (
        <>
            <VStack w="100%">
                <Image position="absolute" zIndex="-1" src={"/images/banner_test_2.jpeg"} alt="마루미디어" w="100%" h={800} />
                <Box w="100%" h={800}>
                    <Center h={800} w="100%">
                        <HStack alignItems={"center"} mt={100} w={moduleWidth} justifyContent={"center"}>
                            <Image position="absolute" src={"/images/muk_small.png"} alt="마루미디어" w={moduleWidth} h={moduleWidth}></Image>

                            <Image width={40} height={40} src={"/images/logo_text_white.png"} alt="maru" mr={2} />
                            <Text zIndex={10} color="white" fontWeight="medium" fontSize="2xl">
                                MaruMedia
                            </Text>
                        </HStack>

                        <Heading
                            textAlign="center"
                            w={{ base: "90%", md: "60%", lg: "48%" }}
                            color="coolGray.100"
                            fontSize={{ base: "lg", md: "3xl" }}
                            fontWeight="medium"
                            lineHeight="xs"
                            mt={35}
                            zIndex={10}
                        >
                            We'll Make It Your Imagination Video.
                        </Heading>
                    </Center>
                </Box>
            </VStack>
            <Box w="100%" bg={"warmGray.300"}>
                <Box
                    w="100%"
                    justifyContent={"center"}
                    alignItems={"center"}
                    style={{
                        backgroundImage: "url(/images/muk_line_3.png)",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <HStack px={8} py={50} maxW={1920}>
                        <Hidden till="md">
                            <Image
                                mx={10}
                                my={5}
                                borderWidth={1}
                                borderColor={"coolGray.400"}
                                borderRadius={70}
                                src={"/images/retro.jpg"}
                                alt="마루미디어"
                                w={400}
                                h={550}
                            ></Image>
                        </Hidden>
                        <VStack justifyContent={"center"} alignItems="center" overflow={"hidden"}>
                            <EntypoLightBulb style={{ width: "40", height: "40", color: "#e5e7eb" }} />
                            <Heading {...introHeading} mt="8">
                                미디어 시대를 살아가는 요즘
                            </Heading>
                            <Heading {...introHeading}> 당신의 삶은 콘텐츠로 잘 표현되고 있나요? </Heading>
                            <Heading {...introHeading}>잠시도 우리 곁을 떠나지 않는 다양한 영상들</Heading>
                            <Heading {...introHeading}>그런데! 정작 나에게 필요한 영상들</Heading>
                            <Heading {...introHeading}>지금 딱 마음에 드는 영상들은 주변에서 찾기 어려우시죠?</Heading>
                            <Divider></Divider>
                            <Heading {...introHeading2} mt={10}>
                                <Text underline bold>
                                    마루미디어
                                </Text>
                                는 20년 동안 방송가에 살아온
                            </Heading>
                            <Heading {...introHeading2}>이들로 이루어진 콘텐츠 제작 업체로,</Heading>

                            <Heading {...introHeading2}>간단한 촬영에서부터 다양한 영상제작은 물론이고,</Heading>

                            <Heading {...introHeading2}>사람들이 원하는 홍보영상까지 만들어 드리고 있습니다.</Heading>
                        </VStack>
                    </HStack>
                </Box>
            </Box>

            <section id="OurService"></section>
            <Center
                w="100%"
                style={{
                    backgroundImage: "url(/images/banner_test_3.jpeg)",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}
            >
                {/* <Image position="absolute" top={0} src={"/images/banner_test_3.jpeg"} alt="마루미디어" w="100%" h={800}></Image> */}
                <Box
                    minHeight={800}
                    justifyContent={"center"}
                    alignItems="center"
                    w="100%"
                    bg={{
                        linearGradient: {
                            colors: ["#00001570", "#000015bb"],
                            start: [0, 0],
                            end: [1, 0],
                        },
                    }}
                    py={{ base: 10, md: 10, sm: 10 }}
                >
                    <Heading my={10} mb={3} textAlign="left" color="coolGray.200" fontSize={"4xl"} fontWeight="bold" lineHeight="3xl">
                        Our Service
                    </Heading>
                    <Ourwork />
                </Box>
            </Center>
            <section id="Production"></section>
            <Center
                w="100%"
                style={{
                    backgroundImage: "url(/images/banner_test_6.png)",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}
            >
                {/* <Image position="absolute" top={0} src={"/images/banner_test_3.jpeg"} alt="마루미디어" w="100%" h={800}></Image> */}
                <Box
                    minHeight={500}
                    justifyContent={"center"}
                    alignItems="center"
                    w="100%"
                    bg={{
                        linearGradient: {
                            colors: ["#eeeeff70", "#eeeeffbb"],
                            start: [0, 0],
                            end: [1, 0],
                        },
                    }}
                    pt={{ base: 10, md: 10, sm: 10 }}
                    pb={{ base: 50, md: 50, sm: 50 }}
                >
                    <Heading my={10} mb={3} textAlign="left" color="coolGray.800" fontSize={"4xl"} fontWeight="bold" lineHeight="3xl">
                        Production
                    </Heading>
                    <Production />
                </Box>
            </Center>
            <section id="Portfolio"></section>
            <Box w="100%" mt={-20}>
                <img src={"/images/bb_bt.png"} alt="마루미디어" width="100%" height={100}></img>
                <Box
                    bg="#000015"
                    w="100%"
                    style={{
                        backgroundImage: "url(/images/camera-4830248_1920.jpg)",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Box
                        px={10}
                        pt={10}
                        pb={200}
                        w="100%"
                        alignItems="center"
                        bg={{
                            linearGradient: {
                                colors: ["#000015", "#00001533", "#000015"],
                                start: [1, 1],
                                end: [1, 0],
                            },
                        }}
                    >
                        <Heading my={2} mb={3} textAlign="left" color="coolGray.200" fontSize={"4xl"} fontWeight="bold" lineHeight="3xl">
                            Portfolio
                        </Heading>
                        <Portfolio />
                    </Box>
                </Box>
                <img src={"/images/bb_bb.png"} alt="마루미디어" width="100%" height={100}></img>
            </Box>
        </>
    );
}
