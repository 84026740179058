import React from "react";

import { Heading, Center, HStack, Image, Pressable, Text, VStack, Box, Divider } from "native-base";
import { EntypoDocuments, EntypoVideoCamera, EntypoVideo, EntypoNewMessage, EntypoSoundMix, EntypoCrop } from "react-entypo";

const list = [
    {
        icon: (style) => {
            return <EntypoDocuments style={style} />;
        },
        title: "구성",
        color: "gray.600",
        text: ["① 클라이언트의 영상 제작 의뢰", "② 제작 방법 조율", "③ 일정 조율"],
    },
    {
        icon: (style) => {
            return <EntypoNewMessage style={style} />;
        },
        title: "기획",
        color: "warmGray.600",

        text: ["① 기획 회의", "② 자료수집 및 조사", "③ 시나리오 및 스토리보드 작성", "④ 클라이언트 스토리보드 공유 및 피드백"],
    },
    {
        icon: (style) => {
            return <EntypoVideoCamera style={style} />;
        },
        title: "촬영",
        color: "warmGray.700",

        text: ["① 촬영 (실사)", "② 디자인 (인포그래픽,모션그래픽, 3D 등)편집"],
    },
    {
        icon: (style) => {
            return <EntypoVideo style={style} />;
        },
        title: "편집",
        color: "blueGray.700",

        text: ["① 편집", "② 클라이언트 1차 영상 공유 및 피드백"],
    },
    {
        icon: (style) => {
            return <EntypoSoundMix style={style} />;
        },
        title: "믹싱",
        color: "blueGray.600",

        text: [],
    },
];

const Production = () => {
    return (
        <HStack
            maxW={1920}
            direction={{ base: "row", md: "row" }}
            mt="16"
            mb="100"
            flexWrap="wrap"
            width={{ base: "100%", sm: "100%", md: "100%" }}
            justifyContent={{ base: "center", sm: "center" }}
            space={{ base: 4 }}
        >
            {list.map((item, index) => {
                return (
                    <VStack key={index} w={250} mx={5} my={{ base: 5, sm: 10 }}>
                        <Center>
                            <Box p={8} borderWidth={5} bg={item.color} borderColor="coolGray.400" borderRadius="full">
                                {item.icon({ width: "75", height: "75", color: "#f3f4f6" })}
                            </Box>
                        </Center>

                        <VStack width="100%" alignItems="left" overflow="hidden" mx={{ base: 4, sm: 0 }}>
                            <Heading mt={5} mb={3} textAlign="center" color="gray.800" fontSize={"2xl"} fontWeight="bold" lineHeight="xs">
                                {item.title}
                            </Heading>
                            {item.text.length !== 0 ? <Divider thickness={2} w={250} mb={3} color={"gray.900"} /> : null}
                            {item.text.map((text, index1) => {
                                return (
                                    <Box ml={1} key={index1} color="gray.800" fontWeight="extrabold">
                                        <Text color="coolGray.800" fontWeight="bold" fontSize="md">
                                            {text}
                                        </Text>
                                    </Box>
                                );
                            })}
                        </VStack>
                    </VStack>
                );
            })}
        </HStack>
    );
};

export default Production;
