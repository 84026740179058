import React from "react";
import { Box, Divider, HStack, Icon, Link, Text, VStack, Image, Heading, Stack } from "native-base";
import Location from "./Location";
import MsgForm from "./MsgForm";

export default function Footer() {
    return (
        <Box w="100%" bg="trueGray.400" justifyContent={"center"} alignItems="center">
            <Heading mt={{ base: 20, md: 0 }} my={2} mb={3} textAlign="left" color="coolGray.200" fontSize={"4xl"} fontWeight="bold" lineHeight="3xl">
                Contact
            </Heading>
            <HStack maxW={1920} flexWrap="wrap">
                <Location />
                <MsgForm />
            </HStack>

            <VStack w="100%" mt={[0, 100]} bg={"trueGray.800"} justifyContent="center" alignItems={"center"} py={5}>
                <Text fontSize="3xl" fontWeight="extrabold" mb={4} color="coolGray.200">
                    MaruMedia 마루미디어
                </Text>
                <Text fontSize="sm" color={"coolGray.100"}>
                    사업자등록번호 : 822-17-01523 | 대표이사 : 김구한
                </Text>
                <Text fontSize="sm" color={"coolGray.100"}>
                    주소 : 경상북도 안동시 태사2길 66. 2층 | 대표전화 : 1544-1544
                </Text>
                <Divider mt="2" width="100%" bg="coolGray.400" />
                <HStack mt="2" mb="2">
                    <Text fontSize="xs" fontWeight="light" color={"coolGray.100"}>
                        COPYRIGHTⓒ 2022 MARUMEDIA INC. ALL RIGHTS RESERVED
                    </Text>
                </HStack>
            </VStack>
        </Box>
    );
}
