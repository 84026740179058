import React, { useState, useEffect } from 'react';
import { Box, HamburgerIcon, HStack, Pressable, Text, Hidden, Image } from 'native-base';

import { Link as SLink } from 'react-scroll';

export default function Head({ setSlideOpen }) {
    const [isTop, setIsTop] = useState(false);
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = (e) => {
            const value = window.scrollY;

            setScrollY(value / 500);

            if (value === 0) {
                setIsTop(false);
            } else {
                setIsTop(true);
            }

            //console.log('scrolled', value);
            return;
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll); //clean up
        };
    }, []);

    const addAlpha = (hex, op) => {
        if (op > 1) op = 1;
        var alpha = Math.round(op * 255).toString(16);
        if (alpha.length < 2) alpha = '0' + alpha;
        return hex + alpha;
    };

    const MenuTextColor = !isTop ? '#f3f4f6' : '#111827';

    return (
        <Box
            w="100%"
            p={3}
            h={['65px', '85px']}
            alignItems="center"
            style={{ position: 'fixed', transition: 'all 0s ease-out' }}
            zIndex={1000}
            bg={isTop ? addAlpha('#f3f4f6', scrollY) : ''}
        >
            <HStack w="100%" maxW="1100" justifyContent="space-between">
                <Hidden till="md">
                    <SLink activeClass="active" to="top" spy={true} smooth={true} duration={700} style={{ cursor: 'pointer' }}>
                        <HStack alignItems="center">
                            <Image width={16} height={16} src={'/images/logo.png'} alt="마루미디어" mr="2" />

                            <Text color={MenuTextColor} fontWeight="medium" fontSize="lg">
                                MaruMedia
                            </Text>
                        </HStack>
                    </SLink>
                    <HStack space="10" alignItems="center">
                        <SLink
                            activeClass="active"
                            to="OurService"
                            spy={true}
                            smooth={true}
                            offset={-80}
                            duration={700}
                            style={{ cursor: 'pointer' }}
                        >
                            <Text color={MenuTextColor} fontWeight="medium" fontSize="lg">
                                OurService
                            </Text>
                        </SLink>
                        <SLink
                            activeClass="active"
                            to="Production"
                            spy={true}
                            smooth={true}
                            offset={-80}
                            duration={700}
                            style={{ cursor: 'pointer' }}
                        >
                            <Text color={MenuTextColor} fontWeight="medium" fontSize="lg">
                                Production
                            </Text>
                        </SLink>
                        <SLink activeClass="active" to="Portfolio" spy={true} smooth={true} offset={-80} duration={700} style={{ cursor: 'pointer' }}>
                            <Text color={MenuTextColor} fontWeight="medium" fontSize="lg">
                                Portfolio
                            </Text>
                        </SLink>
                        <SLink activeClass="active" to="Contact" spy={true} smooth={true} offset={-80} duration={700} style={{ cursor: 'pointer' }}>
                            <Text color={MenuTextColor} fontWeight="medium" fontSize="lg">
                                Contact
                            </Text>
                        </SLink>
                    </HStack>
                </Hidden>
                <Hidden from="md">
                    <HStack w="100%" justifyContent="space-between" px={3} py={0} m={0}>
                        <Pressable m="3" onPress={() => setSlideOpen(true)}>
                            <HamburgerIcon color={MenuTextColor} size="5" />
                        </Pressable>
                        <SLink activeClass="active" to="top" spy={true} smooth={true} duration={700} style={{ cursor: 'pointer' }}>
                            <HStack alignItems="center">
                                <Image width={10} height={10} src={'/images/logo.png'} alt="마루미디어" mr="2" />

                                <Text color={MenuTextColor} fontWeight="medium" fontSize="mg">
                                    MaruMedia
                                </Text>
                            </HStack>
                        </SLink>
                    </HStack>
                </Hidden>
            </HStack>
        </Box>
    );
}
