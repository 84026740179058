import { NativeBaseProvider } from "native-base";
import Home from "./page/Home";
import "./App.css";
const config = {
    dependencies: {
        // For Expo projects (Bare or managed workflow)
        "linear-gradient": require("react-native-web-linear-gradient").default, // For non expo projects
        // 'linear-gradient': require('react-native-linear-gradient').default,
    },
};

function App() {
    return (
        <NativeBaseProvider config={config}>
            <Home />
        </NativeBaseProvider>
    );
}

export default App;
