import React, { useState, useEffect, useRef } from "react";
import { Box, Divider, HStack, Icon, FormControl, Text, VStack, Input, Button, Stack, TextArea, Switch, Select } from "native-base";
import { EntypoUser, EntypoPhone, EntypoChevronSmallDown, EntypoEmail, EntypoDotSingle, EntypoCrop } from "react-entypo";

export default function Footer() {
  const [service, setService] = React.useState("");

  return (
    <Box bg="trueGray.100" justifyContent={"center"} alignItems="center" py={8} px={[0, 10]} borderRadius={[0, "xl"]} m={[0, 5]} w={["100%", ""]}>
      <Text fontSize="2xl" fontWeight="extrabold" color="coolGray.600" mb={5}>
        제작문의
      </Text>
      <VStack w="100%" px={10} space={1}>
        <FormControl mb="2">
          <FormControl.Label>이름</FormControl.Label>
          <Input bg="white" InputLeftElement={<EntypoUser style={{ paddingLeft: "10px", color: "#aaa" }} />} placeholder="성함을 입력하세요" />
        </FormControl>
        <FormControl mb="2">
          <FormControl.Label>연락처</FormControl.Label>
          <Input bg="white" InputLeftElement={<EntypoPhone style={{ paddingLeft: "10px", color: "#aaa" }} />} placeholder="연락처를 입력하세요" />
        </FormControl>
        <FormControl mb="2">
          <FormControl.Label>이메일</FormControl.Label>
          <Input
            bg="white"
            InputLeftElement={<EntypoEmail style={{ paddingLeft: "10px", color: "#aaa" }} />}
            placeholder="이메일 주소를 입력하세요"
          />
        </FormControl>

        <FormControl mb="2">
          <FormControl.Label>제작형태</FormControl.Label>
          <Select
            selectedValue={service}
            minWidth="200"
            placeholder="제작형태 선택"
            _selectedItem={{
              bg: "White",
              endIcon: <EntypoChevronSmallDown size="5" />,
            }}
            m={0}
            onValueChange={(itemValue) => setService(itemValue)}
          >
            <Select.Item label="촬영작업" value="ux" />
            <Select.Item label="편집작업" value="web" />
            <Select.Item label="CG 작업" value="cross" />
            <Select.Item label="종합 영상 및 홍보" value="ui" />
            <Select.Item label="기타" value="backend" />
          </Select>
        </FormControl>
        <HStack justifyContent={"space-between"}>
          <FormControl mb="2" w={20}>
            <FormControl.Label>기획</FormControl.Label>
            <Switch size="sm" />
          </FormControl>
          <FormControl mb="2" w={20}>
            <FormControl.Label>촬영</FormControl.Label>
            <Switch size="sm" />
          </FormControl>
          <FormControl mb="2" w={20}>
            <FormControl.Label>나레이션</FormControl.Label>
            <Switch size="sm" />
          </FormControl>
        </HStack>

        <FormControl mb="2">
          <FormControl.Label>상세내용</FormControl.Label>
          <TextArea bg="white" h={20} placeholder="주요 문의 내용을 일력해 주세요" />
        </FormControl>
        <Divider />
        <Button colorScheme="info">문의등록</Button>
      </VStack>
    </Box>
  );
}
