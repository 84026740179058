import React, { useState, useRef } from "react";

import { Center, HStack, Image, Text, AspectRatio, VStack, Box } from "native-base";
import { Modal, Button } from "antd";
import ReactPlayer from "react-player";
import { CloseOutlined } from "@ant-design/icons";

const list = [
    {
        imageUri: "/images/sample3.jpg",
        text: "교통안전캠페인",
        url: "/video/sample3.mp4",
    },
    {
        imageUri: "/images/sample5.jpg",
        text: "비츠로",
        url: "/video/sample5.mp4",
    },
    {
        imageUri: "/images/sample6.jpg",
        text: "스마트워시",
        url: "/video/sample6.mp4",
    },
    {
        imageUri: "/images/sample8.jpg",
        text: "쑤테이블",
        url: "/video/sample8.mp4",
    },
];

function Portfolio() {
    const [isVideoOpen, setVideoOpen] = useState(false);
    const [currentUrl, setcurrentUrl] = useState("");
    const player = useRef();

    return (
        <HStack maxW={1920} direction={{ base: "column", md: "row" }} mt="16" flexWrap="wrap" justifyContent={{ base: "", sm: "center" }}>
            {list.map((item, index) => (
                <VStack
                    key={index}
                    m={3}
                    alignItems="center"
                    borderWidth={[3, 7]}
                    borderColor="gray.300"
                    overflow="hidden"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setcurrentUrl(item.url);
                        setVideoOpen(true);
                    }}
                >
                    <AspectRatio ratio={16 / 10} height={[200, 350]}>
                        <Image
                            source={{
                                uri: item.imageUri,
                            }}
                            resizeMode="cover"
                            alt="Image"
                        />
                    </AspectRatio>
                    <Text bg={"coolGray.800"} px={5} position={"absolute"} bottom={5} color="coolGray.100" fontWeight="extrabold" fontSize="xl">
                        {item.text}
                    </Text>
                </VStack>
            ))}

            <Modal
                destroyOnClose
                centered
                maskStyle={{ backgroundColor: "#111111cc" }}
                bodyStyle={{ backgroundColor: "transparent", display: "flex", flexDirection: "column" }}
                style={{ backgroundColor: "transparent" }}
                width={"100%"}
                open={isVideoOpen}
                onCancel={() => setVideoOpen(false)}
                safeAreaTop={true}
                footer={null}
            >
                <Center>
                    <Button
                        style={{ marginBottom: 20 }}
                        shape="circle"
                        icon={<CloseOutlined />}
                        onClick={() => {
                            setVideoOpen(false);
                        }}
                    ></Button>
                    <ReactPlayer ref={player} playing controls url={currentUrl} />
                </Center>
            </Modal>
        </HStack>
    );
}

export default Portfolio;
