import React, { useState, useEffect, useRef } from "react";

import { Box, Divider, HStack, Icon, Link, Text, VStack, Image, Pressable, Stack } from "native-base";

export default function Location() {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    const mapElement = useRef(null);

    useEffect(() => {
        const { naver } = window;
        if (!mapElement.current || !naver) return;

        const location = new naver.maps.LatLng(36.5673175, 128.732508);
        const mapOptions: naver.maps.MapOptions = {
            center: location,
            zoom: 18,
            zoomControl: true,
            zoomControlOptions: {
                position: naver.maps.Position.TOP_RIGHT,
            },
        };
        const map = new naver.maps.Map(mapElement.current, mapOptions);
        new naver.maps.Marker({
            position: location,
            icon: {
                url: "./logo128.png",
                size: new naver.maps.Size(128, 128),
                scaledSize: new naver.maps.Size(40, 40),

                origin: new naver.maps.Point(0, 0),
                anchor: new naver.maps.Point(20, 20),
            },
            map,
        });
    }, []);

    const ScriptLoad = () => {
        var map = new window.naver.maps.Marker({
            map: new window.naver.maps.Map("map", {
                center: { lat: 36.5673175, lng: 128.732508 },
                zoomControl: true,
                zoom: 18,
            }),
            position: { lat: 36.5673175, lng: 128.732508 },
        });

        var marker = new window.naver.maps.Marker({
            position: new window.naver.maps.LatLng(36.5673175, 128.732508),
            title: "계명대출판부\n보산관 105호",
            map: map,
        });
    };

    return (
        <Box
            bg="trueGray.100"
            justifyContent={"center"}
            alignItems="center"
            py={8}
            px={{ base: 8, md: 10 }}
            w={["100%", ""]}
            borderRadius={["", "xl"]}
            m={{ base: 0, md: 5 }}
        >
            <Text fontSize="2xl" fontWeight="extrabold" color="coolGray.600" mb={5}>
                찾아 오시는길
            </Text>
            <Box width={["100%", 500]} h={["100vw", 500]} ref={mapElement} borderRadius={"xl"} borderWidth={2} borderColor="coolGray.300" />

            <Text fontSize={{ base: "md", md: "2xl" }} fontWeight="extrabold" color="coolGray.600" mt={3}>
                경상북도 안동시 태사2길 66. 2층
            </Text>
        </Box>
    );
}
