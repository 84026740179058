import React from "react";

import { Heading, Center, HStack, Image, Pressable, Text, VStack, Box, Divider } from "native-base";
import { EntypoSound, EntypoVideoCamera, EntypoVideo, EntypoClapperboard, EntypoDotSingle, EntypoCrop } from "react-entypo";

const list = [
  {
    icon: (style) => {
      return <EntypoVideoCamera style={style} />;
    },
    title: "촬영작업",
    text: ["카메라", "드론", "지미집 및 기타 장비"],
    decs: ["방송국 출신 감독들이 매체를 고려해 트렌디한 촬영", "드론 전문가의 창조적인 시각으로 고퀄리티 영상 촬영"],
  },
  {
    icon: (style) => {
      return <EntypoCrop style={style} />;
    },
    title: "편집작업",
    text: ["유튜브", "기업 및 개인 광고", "웨딩 및 행사"],
    decs: ["센스 있는 유튜브, 플랫폼에 최적화된 콘텐츠를 위해 편집", "특별한 날, 특별한 곳을 더 의미 있게 만들어 드립니다."],
  },
  {
    icon: (style) => {
      return <EntypoVideo style={style} />;
    },
    title: "CG 작업",
    text: ["자막", "모션그래픽", "인트로, 로고", "장비 및 기계작동 CG"],
    decs: [
      "감각적인 CG작업, 각종 그래픽 및 자막 작업",
      "임팩트 있는 CI 및 로고 영상제작",
      "트렌디한 인트로를 원하는 느낌 그대로 제작",
      "각종 기계나 장비의 동작 CG구현 및 작동영상 제작",
    ],
  },
  {
    icon: (style) => {
      return <EntypoSound style={style} />;
    },
    title: "종합 영상 및 홍보",
    text: ["인스타그램 등 SNS관리", "유튜브 및 Live방송", "각종 포탈 홍보대행", "PPT용 영상 제작", "교육용 영상 제작", "캠페인 제작", "기획 작업"],
    decs: [],
  },
  {
    icon: (style) => {
      return <EntypoClapperboard style={style} />;
    },
    title: "",
    text: [
      "영상홍보대행",
      "SNS 관리대행",
      "파워유튜버 블로거 등 각종 매체 연결",
      "인스타, SNS 위탁 및 관리",
      "관공서 및 기업 PPT용 영상 제작",
      "각종 교육기관의 교육자료용 영상 제작",
      "방송프로그램 및 캠페인/홍보영상제작",
      "위탁기획, 영상기획, 행사기획 등 기획안",
    ],
    decs: [],
  },
];

const OurworkCategories = () => {
  const icostyle = { width: "50", height: "50", color: "#e5e7eb" };

  return (
    <HStack
      maxW={1920}
      direction={{ base: "row", md: "row" }}
      mt="16"
      flexWrap="wrap"
      width={{ base: "100%", sm: "100%", md: "100%" }}
      justifyContent={{ base: "center", sm: "center" }}
      space={{ base: 0, md: 4 }}
    >
      {list.map((item, index) => {
        return (
          <HStack key={index} w={["100%", 520]} px={[3, 0]} mx={[0, 5]} my={{ base: 5, md: 10 }}>
            <Box>
              <Box p={3} borderWidth={5} borderColor="coolGray.500" borderRadius="full">
                {item.icon(icostyle)}
              </Box>
            </Box>

            <VStack ml={3} width="80%" alignItems="left" overflow="hidden" mx={{ base: 4, sm: 0 }}>
              <Heading m={2} mb={3} textAlign="left" color="coolGray.200" fontSize={"2xl"} fontWeight="bold" lineHeight="xs">
                {item.title}
              </Heading>
              {item.title ? <Divider w={250} mb={3} color={"coolGray.800"} /> : null}
              {item.text.map((text, index1) => {
                return (
                  <Box pl={1} pr={[8, 0]} key={index1} color="coolGray.800" fontWeight="extrabold">
                    <Text color="coolGray.200" fontWeight="bold" fontSize={["md", "lg"]}>
                      <EntypoDotSingle /> {text}
                    </Text>
                  </Box>
                );
              })}
              <Box mb={3}></Box>
              {item.decs.map((decs, index2) => {
                return (
                  <Box pl={7} pr={[8, 0]} key={index2} color="coolGray.800" fontWeight="extrabold">
                    <Text color="coolGray.300" fontWeight="medium" fontSize={["sm", "md"]}>
                      {decs}
                    </Text>
                  </Box>
                );
              })}
            </VStack>
          </HStack>
        );
      })}
    </HStack>
  );
};

export default OurworkCategories;
